import { Divider, Page } from '@geist-ui/core'
import {styled} from 'styled-components'

const logo = require('../img/logo.png')
const home = require('../img/Home.jpg')
const profile = require('../img/Profile.jpg')

const Home = () => {
  return (
    <Home.Container>
    <Home.Page>
      <Page.Header center>
        <Home.Image src={logo} alt='Logo da The Cafe' />
      </Page.Header>
      <Divider />
      <Home.H2>
        Olá, bem-vindo à comunidade The Cafe!
      </Home.H2>
      <Home.P>
        The Cafe é uma comunidade feita por e para todos os apaixonados por café. Em um único lugar você encontra diversos conhecimentos em torno das diferentes marcas e produtores. 
      </Home.P>
      <Home.Links>
        <Home.ImageBanner alt='Imagem da home do app' src={home} />
        <Home.ImageBanner alt='Imagem de perfil do usuário no app' src={profile} />
      </Home.Links>
      <Divider />
      <Home.P>
        Preparamos algumas opções aqui para você. A primeira delas são os dois links para as lojas de aplicativos, sim eles já estão disponíveis. Trabalhamos nele por mais de 6 meses e finalmente lançamos. Caso você opte por criar uma conta, saiba que é possível contribuir e fazer parte das próximas versões.
      </Home.P>
      <Home.Links>
          <a href='http://play.google.com/store/thecafe.app'>
            <Home.LinksImage alt='Disponível no Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/pt_badge_web_generic.png'/>
          </a>
          <a href='https://apps.apple.com/us/app/the-cafe/id6472904370'>
            <Home.LinksImage alt='Disponível no Apple Store' src='https://developer.apple.com/news/images/download-on-the-app-store-badge.png'/>
          </a>
      </Home.Links>
      <Divider />
      <Home.P>
        Mas claro, o bom e tradicional e-mail continua valendo, então se quiser nos mandar uma mensagem ou precisar falar conosco sobre outros assuntos...
      </Home.P>
      <Home.P bold>
        thecafe.thecommunity@gmail.com
      </Home.P>
    </Home.Page>
    </Home.Container>
  )
}

Home.Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`

Home.Page = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
`

Home.Image = styled.img`
  width: 150px;
`

Home.H2 = styled.div`
  font-size: 2.2rem;
  font-weight: bold;
`

Home.P = styled.div`
  font-size: 1rem;
  ${p => p.bold && `
    font-weight: bold;
  `}
`

Home.ImageBanner = styled.img`
  max-width: 150px;
  border: solid 3px #eaeaea;
  border-radius: 5px;
`

Home.Links = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`

Home.LinksImage = styled.img`
  width: 100px;
`

export default Home;
