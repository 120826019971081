import { CssBaseline, GeistProvider } from '@geist-ui/core'
import Home from './pages/Home';

const App = () => {
  return (
    <GeistProvider themeType='light'>
      <CssBaseline />
      <Home />
    </GeistProvider>
  )
}

export default App;
